import React from 'react';
import Layout from '../../components/layout';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';

import './picks.scss';

const Page = () => (
  <Layout>
    <Helmet>
      <body page-name="page-picks" />
    </Helmet>
    <main>
      <h1>
        <Link to="/picks">picks</Link> / software
      </h1>
      <h2>
        developer tools <small>&amp; other miscellaneous software</small>
      </h2>

      <p>programs and utilities i use every day</p>

      <hr />

      <dl>
        {tools
          .sort((a, b) => (a[0].toLowerCase() < b[0].toLowerCase() ? -1 : 1))
          .map(([title, url, desc]) => (
            <React.Fragment key={title}>
              <h3>{title}</h3>
              <p>
                <a href={url} target="_blank">
                  <code>{url}</code>
                </a>
              </p>
              {desc ? <p>{desc}</p> : null}
            </React.Fragment>
          ))}
      </dl>
    </main>
  </Layout>
);

//prettier-ignore
const tools = [
  [
    'Alfred for Mac',
    'https://www.alfredapp.com/',
    'a more powerful, programmable, and customizable spotlight alternative'
  ],
  [
    'VS Code',
    'https://code.visualstudio.com',
    'my current IDE of choice for most web projects (and beyond)'
  ],
  [
    'SourceTree',
    'https://www.sourcetreeapp.com/',
    'GUI git client, great for visualizing repo state even when using the CLI'
  ],
  [
    'Pocket',
    'https://getpocket.com/',
    'a single place to bookmark articles and other links to read later across browsers and devices'
  ],
  [
    'Sequel Pro',
    'https://www.sequelpro.com/',
    'Open-source SQL database management app for MacOS'
  ],
  [
    'mkcert',
    'https://github.com/FiloSottile/mkcert',
    'easy local CA for localhost and test domains'
  ],
  [
    'fkill',
    'https://github.com/sindresorhus/fkill-cli',
    '“fabulously kill processes”'
  ],
  [
    'pandoc',
    'https://pandoc.org/',
    'swiss-army knife for converting documents between markup formats'
  ],
  [
    'Better Touch Tool',
    'https://folivora.ai/',
    'highly customize Mac trackpads and other input devices with multitouch gestures'
  ],
  [
    'Homebrew',
    'https://brew.sh/',
    'Indispensable package manager for MacOS'
  ]
];

export default Page;
